import { Product } from '@/modules/product/queries/types';

import { ProductsCarouselTitle } from '../ProductsCarousel/components/ProductsCarouselTitle';
import { ProductsCarousel } from '../ProductsCarousel/ProductsCarousel';
import { SeeAllTile } from '../SeeAllTile/SeeAllTile';

type CategoryProductSectionCarouselProps = {
  products: readonly Product[];
  title: string;
  categoryUrl: string | null;
  hasMore: boolean;
  seeAllTitle?: string;
};

export const CategoryProductsSectionCarousel = ({
  products,
  title,
  categoryUrl,
  hasMore,
  seeAllTitle,
}: CategoryProductSectionCarouselProps) => {
  const customSlides =
    hasMore && categoryUrl && seeAllTitle
      ? [<SeeAllTile text={seeAllTitle} url={categoryUrl} />]
      : undefined;

  return (
    <ProductsCarousel
      sectionName={title}
      products={products}
      title={<ProductsCarouselTitle sectionUrl={categoryUrl} title={title} />}
      customSlides={customSlides}
    />
  );
};
